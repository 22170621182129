<template>
  <div class="account-my-favorites-tab">
    <div class="account-page-tab__header">
      <h2>
        Мои избранные услуги
      </h2>
      <div class="account-page-tab__settings">
        <esmp-button
          class="account-page-tab__settings-button"
          view="function"
          icon="24-settings"
          title="Включить режим сортировки"
          @click.stop="toggleServicesSortMode"
        />
        <h5
          v-if="isDraggable"
          class="account-page-tab__settings-hint"
        >
          Перемещение возможно в рамках одной страницы
        </h5>
      </div>
    </div>

    <template v-if="mappedServices">
      <div class="account-my-favorites-tab__items" :key="servicesUpdateKey">
        <draggable
          v-model="mappedServices"
          :options="{ disabled: !isDraggable }"
          handle=".account-page-tab__item--draggable"
        >
          <service-list-item
            v-for="service in mappedServices"
            :key="`service-${service.id}`"
            :service="service"
            :class="[
              'account-page-tab__item',
              {
                'account-page-tab__item--draggable': isDraggable,
              }
            ]"
          />
        </draggable>
      </div>

      <div class="account-page-tab__pagination">
        <esmp-pagination-adaptive
          :current-page.sync="currentPage"
          :page-count="pageCount"
        />
      </div>
    </template>

    <filler-block
      v-else
      is-big
      icon="empty-favorites"
      :icon-width="251"
      title="Мои избранные услуги"
      subtitle="Здесь будут отображаться ваши избранные услуги.
        Избранные услуги - это быстрый доступ к часто используемым услугам.
        Воспользуйтесь поиском и добавляйте необходимые услуги в избранное."
    />

    <esmp-loader v-if="loading" fix />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions } from 'vuex';
import ServiceListItem from '@/components/service-list-item';
import { FAVORITES_PER_PAGE } from '@/constants/account-page';
import { getPagesCount, getPaginatedArray } from '@/utils/pagination';
import FillerBlock from '@/components/filler-block';

export default {
  name: 'AccountMyFavoritesTab',
  components: { FillerBlock, ServiceListItem, draggable },
  data() {
    return {
      loading: false,
      servicesUpdateKey: 0,
      currentPage: 1,
      isDraggable: false,
    };
  },

  computed: {
    ...mapState('services', ['favoriteServices']),

    mappedServices: {
      get() {
        const services = this.favoriteServices || [];
        return getPaginatedArray(services, this.currentPage, FAVORITES_PER_PAGE);
      },
      set(val) {
        const services = val.map((service, index) => ({
          ...service,
          sortOrder: index,
        }));
        this.updateSortOrderFavorites(services);
      },
    },

    pageCount() {
      return getPagesCount(this.favoriteServices?.length, FAVORITES_PER_PAGE);
    },
  },

  watch: {
    mappedServices() {
      this.servicesUpdateKey += 1;
    },
  },
  methods: {
    ...mapActions('services', ['updateSortOrderFavorites']),

    toggleServicesSortMode() {
      this.isDraggable = !this.isDraggable;
    },
  },
};
</script>
